import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// export default {
//   metaInfo: {
//   //改变当前路由的title
//     title: 'title',
//      //改变当前路由的link
//     link: [
//       {
//         rel: 'baidu',
//         href: 'https://www.baidu.com/'
//       },
//     ],
//   },
//   data(){
//     return{}
//   }
// }

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    redirect: "index", //默认跳转到index页面
    children: [
      {
        path: "/index",
        name: "index",

        component: () => import("../views/index.vue"),

      },

      // 解决方案页面
      {
        path: "/Solution",
        name: "Solution",

        component: () => import("../views/Solution.vue"),
      },
      //关于我们
      {
        path: "/AboutUs",
        name: "AboutUs",

        component: () => import("../views/AboutUs.vue"),
        // children: [
        //   //加入我们-招聘
        //   {
        //     path: "/Join-us",
        //     name: "Join-us",
        //     component: () =>
        //       import(/* webpackChunkName: "about" */ "../views/Join-us.vue"),
        //   },
        // ],
      },
      //未来lab
      {
        path: "/futureLab",
        name: "futureLab",

        component: () => import("../views/futureLab.vue"),
      },
      //加入我们-招聘
      {
        path: "/Join-us",
        name: "Join-us",
        component: () => import("../views/Join-us.vue"),
      },
    ],
  },
  //产品——未来之光
  {
    path: "/Product-WLZG",
    name: "Product-WLZG",
    meta: {
      title: "未来之光",
    },

    component: () => import("../views/Product-WLZG.vue"),
  },
  //产品——小象脑力
  {
    path: "/Product-XXNL",
    name: "Product-XXNL",
    meta: {
      title: "小象脑力",
    },

    component: () => import("../views/Product-XXNL.vue"),
  },
  //产品——倍速阅读
  {
    path: "/Product-BSYD",
    name: "Product-BSYD",
    meta: {
      title: "倍速阅读",
      link: {
        rel: "icon",
        href:
            "https://eletell.oss-cn-beijing.aliyuncs.com/H5_Activity/Website/berdlogo.ico",
      },
    },

    component: () => import("../views/Product-BSYD.vue"),
  },
  //产品——小晴同学
  {
    path: "/Product-XQTX",
    name: "Product-XQTX",
    meta: {
      title: "小晴同学",
      link: {
        rel: "icon",
        href:
            "https://eletell.oss-cn-beijing.aliyuncs.com/H5_Activity/Website/xqtx.ico",
      },
    },

    component: () => import("../views/Product-XQTX.vue"),
  },
  //产品——超级记忆力
  {
    path: "/Product-JYL",
    name: "Product-JYL",
    meta: {
      title: "超级记忆力",
      link: {
        rel: "icon",
        href:
            "https://eletell.oss-cn-beijing.aliyuncs.com/H5_Activity/Website/jyl.ico",
      },
    },

    component: () => import("../views/Product-JYL.vue"),
  },
  //产品——大脑音乐
  {
    path: "/Product-DTx",
    name: "Product-DTx",
    meta: {
      title: "开智脑波",
      link: {
        rel: "icon",
        href:
            "https://eletell.oss-cn-beijing.aliyuncs.com/H5_Activity/Website/dtx.ico",
      },
    },

    component: () => import("../views/Product-DTx.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  window, scrollTo(0, 0);
  console.log("window.document", window.document.body);
  window.document.title =
      to.meta.title == undefined ? "北京大象智酷科技有限公司" : to.meta.title;

  //  第一次进入项目
  console.log(window.localStorage.getItem("user_token"));
  if (to.meta.requireAuth) {
    let token = window.localStorage.getItem("user_token");
    let userInfo = window.localStorage.getItem("userInfo");
    console.log(to);
    if (!token && to.path != "/author") {
      window.localStorage.setItem("beforeLoginUrl", to.fullPath); // 保存用户进入的url

      next("/author");
      return false;
    } else if (token && !userInfo) {
      console.log(token);
      fetchGet("login", {openid: token})
      .then((data) => {
        console.log("成功");
        window.localStorage.setItem(
            "userInfo",
            JSON.stringify(data.data.data)
        );
        next();
      })
      .catch((err) => {
        console.log("失败");
        window.localStorage.removeItem("user_token");
        window.location.reload();
        return false;
      });
    }
    next();
  } else {
    next();
  }
});

export default router;
